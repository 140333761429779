import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import OnDemandVideoIcon from '@material-ui/icons/OndemandVideo'
import SearchIcon from '@material-ui/icons/Search'
import { propOr } from 'ramda'

const mobileTabs = (args) => [
  {
    id: 'home',
    name: 'Home',
    icon: <HomeOutlinedIcon style={{ fontSize: '20px' }} />,
    route: '/',
    isActive: () => args?.asPath === '/',
    counter: 0,
  },
  {
    id: 'discover',
    name: 'Discover',
    icon: <SearchIcon style={{ fontSize: '20px' }} />,
    route: '/discover',
    isActive: () => args?.asPath.startsWith('/discover'),
    counter: 0,
  },
  {
    id: 'classroom',
    name: 'Classroom',
    icon: <OnDemandVideoIcon style={{ fontSize: '20px' }} />,
    route: '/classroom',
    isActive: () => args?.asPath.startsWith('/classroom'),
    counter: propOr(0, 'activeClasses')(args),
  },
  {
    id: 'dashboard',
    name: 'Dashboard',
    icon: <DashboardOutlinedIcon style={{ fontSize: '20px' }} />,
    route: '/dashboard',
    isActive: () => args?.asPath.startsWith('/dashboard'),
    counter: 0,
  },
  {
    id: 'chat',
    name: 'Messages',
    icon: <ChatBubbleOutlineIcon style={{ fontSize: '18px' }} />,
    route: '/messages',
    isActive: () => args?.asPath.startsWith('/message'),
    counter: 0,
  },
]

export default mobileTabs
