import Apps from './components/apps'
import Links from './components/links'
import Logout from './components/logout'
import Social from './components/social'
import UserInfo from './components/user-info'
import { MobileMenuEl } from './elements'
import MobileMenuStore from '../../stores/mobile-menu-store'

const MobileMenu = () => {
  const { isOpen } = MobileMenuStore.useContainer()

  return (
    <MobileMenuEl>
      {isOpen && (
        <>
          <UserInfo />
          <Links />
          <Apps />
          <Social />
          <Logout />
        </>
      )}
    </MobileMenuEl>
  )
}

export default MobileMenu
