/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import { background1, background2, darkAccent } from '../../../../../styles/colors'
import { hFlex } from '../../../../../styles/style-helpers'
import navigationDimensions from '../../../config'

export const MobileTabsEl = styled.div`
  width: 100%;
  height: ${navigationDimensions.tabsHeightMobile}em;
  background: ${background1};
  border-top: 1px solid ${background2};
  ${hFlex};
  justify-content: space-between;
  padding: 0 1em;

  @media screen and (min-device-width: 769px) {
    display: none;
  }

  a {
    ${hFlex};
    border-radius: 2em;
    padding: 0.5em 1em 0.5em 1em;

    .MuiBadge-badge {
      background: ${darkAccent};
    }

    button {
      padding: 0;
      margin-right: 0.5em;

      svg {
        font-size: 1.125em;
        transition: fill 150ms ease;
      }
    }

    p {
      overflow: hidden;
      color: #e7e7e7;
    }

    &.active {
      button {
        margin-right: 0.33em;

        svg {
          fill: #e7e7e7;
        }
      }
    }
  }
`
