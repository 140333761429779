import { createGlobalStyle } from 'styled-components'

const SnackBarStyles = createGlobalStyle`
  #notistack-snackbar {
    .MuiSvgIcon-root {
      fill: white
    }
  }
`

export default SnackBarStyles
