import Api from '../../../api/configuration'

const getApiService = (serverName) =>
  Api[
    serverName === 'main'
      ? 'coreProtected'
      : serverName === 'public-cache'
      ? 'cachedPublic'
      : serverName === 'sls-core'
      ? 'slsCoreProtected'
      : serverName === 'sls-core-public'
      ? 'slsCorePublic'
      : 'corePublic'
  ]

export default getApiService
