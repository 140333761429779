import PropTypes from 'prop-types'
import withStore from '../../utils/with-store'
import AuthStore from './stores/auth-store'
import ProfileStore from './stores/profile-store'
import TeacherProfileStore from './stores/teacher-profile-store'

const UserWrapper = ({ children }) => {
  return children
}

export default withStore([TeacherProfileStore, ProfileStore, AuthStore])(
  UserWrapper,
)

UserWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}
