import Head from 'next/head'
import PropTypes from 'prop-types'

import { H3, Text } from '../../styles/typography'
import { HeaderEl } from './elements'

const Header = ({ title, subtitle, noMeta }) => {
  return (
    <HeaderEl>
      {!noMeta && (
        <Head>
          <title>{`${title} | LivDemy`}</title>
          <meta content={subtitle} name='description' property='description' />
        </Head>
      )}
      <H3>{title}</H3>
      <Text>{subtitle}</Text>
    </HeaderEl>
  )
}

export default Header

Header.propTypes = {
  noMeta: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

Header.defaultProps = {
  noMeta: false,
  subtitle: '',
}
