import { identity } from 'ramda'
import useSWR from 'swr'

import ProfileStore from '../modules/user/stores/profile-store'

const useSwr = ({
  url = '',
  parser = identity,
  initialData,
  options = {},
  condition = true,
  name = '',
  server = 'main',
  errorNotification = true,
}) => {
  const {
    loading,
    data: { id: userId = 'NOT_LOGGED_IN' } = {},
  } = ProfileStore.useContainer()
  return useSWR(
    !loading && condition
      ? [url, parser, server, userId, name, errorNotification]
      : null,
    undefined,
    {
      ...options,
      initialData,
    },
  )
}

export const useSwrWithoutAuth = ({
  url = '',
  parser = identity,
  initialData,
  options = {},
  condition = true,
  name = '',
  server = 'main',
  errorNotification = true,
}) =>
  useSWR(
    condition
      ? [url, parser, server, 'WITHOUT_AUTH', name, errorNotification]
      : null,
    undefined,
    {
      ...options,
      initialData,
    },
  )

export default useSwr
