import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return useCallback(
    (variant, message = '', options = {}) => {
      const { onClick = () => {}, ...otherOptions } = options
      const key = enqueueSnackbar(message, {
        onClick: () => {
          onClick()
          closeSnackbar(key)
        },
        variant,
        ...otherOptions,
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )
}

export default useNotify
