/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import bugsIllustration from '../../../assets/illustrations/bugs.svg'
import { background1 } from '../../../styles/colors'
import { background, hFlex, overlay } from '../../../styles/style-helpers'

export const ErrorFallbackEl = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${background1};

  .graphic {
    position: relative;
    width: calc(100% - 2em);
    height: 30vh;
    margin: 0 1em 2em;
    border-radius: 0.5em;
    background: white;

    > div {
      ${overlay};
      ${background(bugsIllustration, 'contain')};
      transform: scale(0.85);
    }
  }

  > p {
    width: calc(100% - 2em);
    margin: 0 1em 1em;
    line-height: 1.5;
  }

  .actions {
    width: 100%;
    padding: 0 1em;
    ${hFlex};
    justify-content: flex-end;
  }
`
