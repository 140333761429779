import { assoc, has, identity, ifElse, lens, over, prop } from 'ramda'
import Api from '../../../api/configuration'
import ipInfo from '../../../api/misc/ip-info'
import UserTokens from '../../../schemas/user/tokens'

const parser = ifElse(
  has('success'),
  over(lens(prop('result'), assoc('result')), UserTokens),
  identity,
)

const AuthApis = {
  passwordLogin: (email, password) =>
    Api.corePublic
      .post('auth/login/regular/step1', { email, password })
      .then(parser),
  fbAuth: async (token) =>
    Api.corePublic
      .post('auth/signup/fb/step1', {
        token,
        ...(await ipInfo()),
      })
      .then(parser),
  googleAuth: async (token) =>
    Api.corePublic
      .post('auth/signup/google/step1', {
        token,
        ...(await ipInfo()),
      })
      .then(parser),
  register: async (name, email, password) =>
    Api.corePublic
      .post('auth/register', {
        name,
        email,
        password,
        ...(await ipInfo()),
      })
      .then(parser),
}

export default AuthApis
