const withStore = (stores = []) => (WrappedComponent) => ({ data, ...props }) =>
  stores.reduce(
    (component, Store) => (
      <Store.Provider initialState={data}>{component}</Store.Provider>
    ),
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WrappedComponent {...props} data={data} />,
  )

export default withStore
