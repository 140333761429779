import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import Button from '../../components/button'
import Header from '../../components/header'
import { Text } from '../../styles/typography'
import withStore from '../../utils/with-store'
import { ErrorFallbackEl } from './elements/fallback'
import ErrorLoggerStore from './stores/error-logger-store'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { loaded } = ErrorLoggerStore.useContainer()

  useEffect(() => {
    if (loaded) {
      Sentry.captureException(error)
    }
  }, [error, loaded])

  return (
    <ErrorFallbackEl>
      <Header
        subtitle={"Don't worry, help is on the way!"}
        title='Something went wrong...'
      />
      <div className='graphic'>
        <div />
      </div>
      <Text>
        Our developers have been notified of the issue and will be fixing this
        as a priority.
      </Text>
      <Text>Apologies for the inconvenience caused.</Text>
      <div className='actions'>
        <Button onClick={() => resetErrorBoundary()} theme='primary'>
          Try Again
        </Button>
      </div>
    </ErrorFallbackEl>
  )
}

export default withStore([ErrorLoggerStore])(ErrorFallback)

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}
