import { createGlobalStyle } from 'styled-components'
import { background1, background2 } from './colors'

const minFontSize = 12
const maxFontSize = 16

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    color: #e7e7e7;
  }

  html, body, #__next, .app {
    position: relative;
    height: 100%;
    width: 100%;
  }

  #__next {
    z-index: 1
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  html {
    background: ${background1};
    font-family: 'Gotham A', 'Gotham B', 'HomepageBaukasten', sans-serif;
    font-size: ${(minFontSize + maxFontSize) / 2}px;
    font-size: clamp(${minFontSize}px, 3.66vw, ${maxFontSize}px);
  }

  svg.MuiSvgIcon-root {
    fill: #e7e7e7;
  }

  .Mui-disabled {
    svg.MuiSvgIcon-root {
      fill: #a0a0a0
    }
  }

  button {
    opacity: 0;
  }

  .MuiAutocomplete-popper {
    background: ${background2};

    > div {
      background: ${background2};
    }
  }
`

export default GlobalStyles
