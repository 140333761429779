import ms from 'ms'

import isServer from './is-server'

// todo: replace this implementation with a context-store

// eslint-disable-next-line import/no-mutable-exports
let dimensions = {
  dh: (value) => (value * 736) / 100,
  dw: (value) => (value * 414) / 100,
  rem: (value) => value * 16,
  vh: (value) => (value * 736) / 100,
  vw: (value) => (value * 414) / 100,
}

export const updateDimensions = () => {
  dimensions = {
    dh: (value) =>
      (value * parseFloat(isServer() ? 736 : window.screen.availHeight)) / 100,
    dw: (value) =>
      (value * parseFloat(isServer() ? 414 : window.screen.availWidth)) / 100,
    rem: (value) =>
      value *
      parseFloat(
        isServer() ? 16 : getComputedStyle(document.documentElement).fontSize,
      ),
    vh: (value) =>
      (value *
        parseFloat(
          isServer()
            ? 736
            : window?.innerHeight ||
                getComputedStyle(document.documentElement).height,
        )) /
      100,
    vw: (value) =>
      (value *
        parseFloat(
          isServer()
            ? 414
            : window?.innerWidth ||
                getComputedStyle(document.documentElement).width,
        )) /
      100,
  }
}

updateDimensions()

setInterval(() => updateDimensions(), ms('2s'))

if (!isServer()) {
  window.addEventListener('resize', updateDimensions)
}

export default dimensions
