import fetch from '@apicase/adapter-fetch'
import { ApiService } from '@apicase/services'

import { apiErrorHandler, otherErrorHandler } from '../error/global'

const base = new ApiService({
  adapter: fetch,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  hooks: {
    before: async ({ payload, next, meta = {} }) => {
      if (meta.headers) {
        const updatedHeaders = {
          ...payload.headers,
          ...meta.headers,
        }
        return next({ ...payload, headers: updatedHeaders })
      }

      return next(payload)
    },
    done: async ({ result, next }) => {
      return next(result.body)
    },
  },
  mode: 'cors',
})
  .on('fail', apiErrorHandler)
  .on('error', otherErrorHandler)

export default base
