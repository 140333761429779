import { AppsEl } from '../elements'

const Apps = () => {
  return (
    <AppsEl>
      <a
        href='https://play.google.com/store/apps/details?id=com.livdemy.app.v1'
        rel='noopener noreferrer'
        target='_blank'
      >
        <div className='playStore' />
      </a>
      <a
        href='https://apps.apple.com/in/app/livdemy/id1540909050'
        rel='noopener noreferrer'
        target='_blank'
      >
        <div className='appStore' />
      </a>
    </AppsEl>
  )
}

export default Apps
