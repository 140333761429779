/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import appStore from '../../../../../assets/images/app-store-badge.png'
import playStore from '../../../../../assets/images/google-play-badge.png'
import { accent } from '../../../../../styles/colors'
import { background, hFlex, overlay, singleLineText, square, vFlex } from '../../../../../styles/style-helpers'

export const MobileMenuEl = styled.div`
  ${overlay};
  background: #232323;
  color: white;
  ${vFlex};
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 34%;
`

export const UserInfoEl = styled.div`
  width: 100%;
  margin: 4em 0;
  padding: 0 1.5em;
  ${hFlex};
  justify-content: flex-end;

  .info {
    width: calc(100% - 2em);
    flex: 1;
    ${vFlex};
    align-items: flex-end;
    padding: 0 1em;

    > * {
      ${singleLineText};
      text-align: right;
      letter-spacing: 0.01em;
    }

    > p {
      opacity: 0.5;
      margin-top: 0.25em;
    }
  }

  .login-register {
    margin-right: 1em;
  }

  .userIcon {
    ${square('10vw')};
    ${hFlex};

    img {
      object-fit: cover;
      border-radius: 0.5em;
    }

    .MuiSvgIcon-root {
      fill: #c7c7c7;
    }
  }
`

export const LinksEl = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0 1.5em;
  overflow: auto;

  .section {
    position: relative;
    padding: 2em 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 1px;
      width: 5em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    a {
      p {
        padding: 0.75em 0;
        width: 100%;
        text-align: right;
        color: #c7c7c7;
        ${hFlex};
        justify-content: flex-end;

        .MuiSvgIcon-root {
          margin-left: 0.5em;
          opacity: 0.75;
          fill: white;
        }
      }

      &.active {
        p {
          color: ${accent};
        }

        svg {
          fill: ${accent};
        }
      }
    }
  }
`

export const SocialEl = styled.div`
  width: 100%;
  padding: 1.5em 0.5em 1.5em 1.5em;
  ${hFlex};
  justify-content: flex-end;

  .MuiSvgIcon-root {
    fill: white;
    font-size: 1.25em;
  }
`

export const AppsEl = styled.div`
  width: 100%;
  padding: 1em 0.5em 0 1.5em;
  ${hFlex};
  justify-content: flex-end;

  a {
    position: relative;
    display: block;
    margin: 0 0.25em;
    flex: 1;
    height: 3.5em;

    div {
      width: 100%;
      height: 100%;

      &.playStore {
        ${background(playStore, 'contain')}
      }

      &.appStore {
        ${background(appStore, 'contain')};
        transform: scale(0.925);
      }
    }
  }
`

export const LogoutEl = styled.div`
  position: relative;
  height: 10%;
  padding: 1.5em;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 5em;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  .MuiSvgIcon-root {
    fill: #c7c7c7;
    font-size: 1.5rem !important;
  }

  button {
    color: #c7c7c7;
  }
`
