import { Star } from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'
import HelpIcon from '@material-ui/icons/Help'
import PolicyIcon from '@material-ui/icons/Policy'

const links = [
  {
    id: 2,
    items: [
      {
        icon: <Star />,
        id: 1,
        name: 'LivDemy Pro',
        route: '/pricing',
      },
      {
        icon: <DescriptionIcon />,
        id: 2,
        name: 'Terms and Conditions',
        route: '/terms',
      },
      {
        icon: <PolicyIcon />,
        id: 3,
        name: 'Privacy Policy',
        route: '/privacy',
      },
      { icon: <HelpIcon />, id: 4, name: 'Support', route: '/support' },
    ],
  },
]

export default links
