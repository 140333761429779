import { useCallback, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { createContainer } from 'unstated-next'

import { useSwrWithoutAuth } from '../../../hooks/use-swr'
import ProfileApis from '../apis/profile'
import AuthStore from './auth-store'

const ProfileStore = createContainer(() => {
  const { loading, loggedIn } = AuthStore.useContainer()

  const [
    savedProfileData,
    updateProfileData,
    removeProfileData,
  ] = useLocalStorage('user-profile-data')

  const { data, error, revalidate } = useSwrWithoutAuth({
    condition: loggedIn,
    initialData: savedProfileData,
    server: 'sls-core',
    url: 'user/profile',
  })

  useEffect(() => {
    if (data) {
      updateProfileData(data)
    } else {
      removeProfileData()
    }
  }, [data, removeProfileData, updateProfileData])

  const updateProfile = useCallback(
    async ({ name, phone, location, gender }) => {
      const { success } = await ProfileApis.updateProfile({
        gender,
        location,
        name,
        phone,
      })

      if (success) {
        await revalidate()
      }

      return success
    },
    [revalidate],
  )

  return {
    data,
    loadFailed: Boolean(error) && !data,
    loading: Boolean(loading || (loggedIn && !data)),
    revalidate,
    updateProfile,
  }
})

export default ProfileStore
