import { Realtime } from 'ably'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useToggle } from 'react-use'
import { createContainer } from 'unstated-next'

import useNotify from '../../../hooks/use-notify'
import AuthStore from '../../user/stores/auth-store'
import ProfileStore from '../../user/stores/profile-store'

const noMessagePopupRoutes = [
  `/messages/[id]`,
  `/[livdemyId]/[bcType]/[url]/[version]/lobby`,
  `/[livdemyId]/[bcType]/[url]/[version]/classroom`,
]

const RealtimeStore = createContainer(() => {
  const notify = useNotify()
  const router = useRouter()
  const { pathname } = useRouter()
  const client = useRef()
  const userChannel = useRef()

  const { loggedIn } = AuthStore.useContainer()
  const { data: { id: userId } = {} } = ProfileStore.useContainer()
  const [loading, toggleLoading] = useToggle(true)
  const [loadFailed, toggleLoadFailed] = useToggle(false)

  useEffect(() => {
    if (loggedIn && userId) {
      client.current = new Realtime(process.env.NEXT_PUBLIC_ABLY_KEY)
      userChannel.current = client.current.channels.get(userId)

      const successHandler = () => {
        toggleLoading(false)
        toggleLoadFailed(false)
      }

      const failureHandler = () => {
        toggleLoading(false)
        toggleLoadFailed(false)
      }

      client.current.connection.on('connected', successHandler)
      client.current.connection.on('failed', failureHandler)
    }
  }, [loggedIn, toggleLoadFailed, toggleLoading, userId])

  useEffect(() => {
    if (!loading && !loadFailed && !router.asPath.endsWith('/classroom')) {
      const newMessageHandler = async ({ data: messageData }) => {
        const { message, conversation } = messageData

        if (noMessagePopupRoutes.includes(pathname)) {
          return
        }

        notify('info', `${message.sender.name}: ${message.message}`, {
          onClick: () => router.push(`/messages/${conversation.id}`),
        })
      }

      userChannel.current.subscribe('CHAT_MESSAGE_RECEIVED', newMessageHandler)
      userChannel.current.subscribe(
        'BROADCAST_CHAT_MESSAGE_RECEIVED',
        newMessageHandler,
      )

      return () => {
        userChannel.current.unsubscribe(
          'CHAT_MESSAGE_RECEIVED',
          newMessageHandler,
        )
        userChannel.current.unsubscribe(
          'BROADCAST_CHAT_MESSAGE_RECEIVED',
          newMessageHandler,
        )
      }
    }

    return () => {}
  }, [loadFailed, loading, notify, pathname, router])

  return {
    client: client.current,
    loadFailed,
    loading,
    userChannel: userChannel.current,
  }
})

export default RealtimeStore
