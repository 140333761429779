import { IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useRouter } from 'next/router'
import backButtonAnimationVariants from '../config/back-animation'
import { BackEl } from '../elements'

const Back = () => {
  const router = useRouter()

  return (
    <BackEl initial='hide-back' variants={backButtonAnimationVariants}>
      <IconButton onClick={() => router.back()}>
        <ArrowBackIosIcon />
      </IconButton>
    </BackEl>
  )
}

export default Back
