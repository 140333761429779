/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { background1 } from '../../../styles/colors'
import { overlay } from '../../../styles/style-helpers'
import navigationDimensions from '../config'

export const NavigationEl = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 1;
`

export const MainEl = styled(motion.div)`
  display: block;
  position: relative;
  height: 100%;
  width: calc(100% - ${navigationDimensions.sideBarWidth}em);
  background: ${background1};

  @media screen and (max-device-width: 769px) {
    width: 100%;
  }
`

export const MenuOverlayEl = styled(motion.div)`
  ${overlay};
  position: fixed;
  z-index: 1;
  display: block;
`

export const ContentEl = styled(motion.div)`
  width: 100%;
  height: calc(100% - ${navigationDimensions.tabsHeightMobile}em);
  padding-top: ${navigationDimensions.topBarHeight}em;
  overflow: auto;

  @media screen and (max-device-width: 769px) {
    width: 100%;
    padding-top: ${navigationDimensions.topBarHeightMobile}em;
  }
`
