/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import { accent } from '../../../styles/colors'

export const HeaderEl = styled.div`
  padding: 1.5em 1em 0;
  height: 9em;

  h3 {
    font-weight: bold;
    color: #f0f0f0;
  }

  p {
    margin-top: 0.5em;
    color: ${accent};
    max-width: 85%;
  }
`
