import * as localforage from 'localforage'
import { applySpec, mergeWith, or, pathOr, pipe } from 'ramda'
import { useEffect } from 'react'
import { createContainer } from 'unstated-next'
import ipInfo from '../../../../../api/misc/ip-info'
import useMap from '../../../../../hooks/use-map'
import useToggle from '../../../../../hooks/use-toggle'

export const USER_PREFERENCES_KEY = 'USER_PREFERENCES'

const PreferencesStore = createContainer(() => {
  const [loaded, toggleLoaded] = useToggle(false)
  const [preferences, { set: setPreference, setAll }] = useMap({})

  useEffect(() => {
    ;(async () => {
      const ip = (await ipInfo()) || {}
      const savedPreferences =
        (await localforage.getItem(USER_PREFERENCES_KEY)) || {}

      const loadedPreferences = pipe(
        mergeWith(or),
        applySpec({
          currency: pathOr('INR', ['currency']),
          timeZone: pathOr('Asia/Kolkata', ['timeZone']),
          location: pathOr('', ['location']),
        }),
      )(savedPreferences, ip)

      setAll(loadedPreferences)

      toggleLoaded(true)
    })()
  }, [setAll, toggleLoaded])

  useEffect(() => {
    ;(async () => {
      if (Object.keys(preferences).length) {
        window.timeZone = preferences.timeZone
        await localforage.setItem(USER_PREFERENCES_KEY, preferences)
      }
    })()
  }, [preferences])

  return { loaded, preferences, setPreference }
})

export default PreferencesStore
