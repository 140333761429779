import navigationDimensions from '../../../config'

const backButtonAnimationVariants = {
  'show-back': {
    opacity: 1,
    width: `${navigationDimensions.topBarHeightMobile * 0.5}em`,
    scale: 1,
  },
  'hide-back': {
    opacity: 0,
    width: 0,
    scale: 0,
  },
}

export default backButtonAnimationVariants
