import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { createContainer } from 'unstated-next'

const PixelTrackingStore = createContainer(() => {
  const router = useRouter()

  const track = useCallback((title = '', data = {}) => {
    if (!window?.fbq) {
      return
    }
    window.fbq('track', title, data)
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      track('PageView')
    })
  }, [router.events, track])

  return { track }
})

export default PixelTrackingStore
