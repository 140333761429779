import localforage from 'localforage'
import { USER_TOKENS_LOCAL_STORE_KEY } from '../../../modules/user/config'
import corePublic from './corePublic'

const REFRESH_TOKEN_API_URL = 'auth/get-auth-token'

const getRefreshToken = () =>
  localforage
    .getItem(USER_TOKENS_LOCAL_STORE_KEY)
    .then((value) => value?.refresh)
    .catch(() => ``)

const updateAuthToken = (token) =>
  localforage
    .getItem(USER_TOKENS_LOCAL_STORE_KEY)
    .then((value) =>
      localforage
        .setItem(USER_TOKENS_LOCAL_STORE_KEY, {
          ...value,
          auth: token,
        })
        .catch(() => ``),
    )
    .catch(() => ``)

const refresh = corePublic
  .extend({
    url: REFRESH_TOKEN_API_URL,
    method: 'POST',
    hooks: {
      before: async ({ payload, next }) => {
        const refreshToken = await getRefreshToken()

        next({ ...payload, body: { refreshToken } })
      },
    },
  })
  .on('done', (res) => updateAuthToken(res.authToken))

export default refresh
