import serviceWrapper from './helper'
import base from './services/base'
import cachedPublic from './services/cachedPublic'
import coreProtected from './services/coreProtected'
import corePublic from './services/corePublic'
import slsCoreProtected from './services/slsCoreProtected'
import slsCorePublic from './services/slsCorePublic'

const Api = {
  base: serviceWrapper(base),
  cachedPublic: serviceWrapper(cachedPublic),
  coreProtected: serviceWrapper(coreProtected),
  corePublic: serviceWrapper(corePublic),
  slsCoreProtected: serviceWrapper(slsCoreProtected),
  slsCorePublic: serviceWrapper(slsCorePublic),
}

export default Api
