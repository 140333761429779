import { createContainer } from 'unstated-next'
import { useLocalStorage } from 'react-use'
import { useEffect } from 'react'
import ProfileStore from './profile-store'
import useSwr from '../../../hooks/use-swr'
import AuthStore from './auth-store'

const TeacherProfileStore = createContainer(() => {
  const { loggedIn } = AuthStore.useContainer()
  const { data: profileData } = ProfileStore.useContainer()

  const [
    savedTeacherProfileData,
    updateTeacherProfileData,
    removeTeacherProfileData,
  ] = useLocalStorage('user-teacher-profile-data')

  const { data, error, revalidate } = useSwr({
    url: 'teacher/info',
    initialData: savedTeacherProfileData,
    condition: loggedIn && profileData?.isTeacher,
  })

  useEffect(() => {
    if (data) {
      updateTeacherProfileData(data)
    } else {
      removeTeacherProfileData()
    }
  }, [data, removeTeacherProfileData, updateTeacherProfileData])

  return {
    loading: !data && !error,
    loadFailed: Boolean(error),
    data,
    revalidate,
  }
})

export default TeacherProfileStore
