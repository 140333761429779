const serviceWrapper = (service) => ({
  delete: (url, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: 'DELETE',
    }),
  get: (url, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: 'GET',
    }),
  post: (url, body, config = {}) =>
    service.doRequest({
      url,
      ...config,
      body,
      method: 'POST',
    }),
  put: (url, body, config = {}) =>
    service.doRequest({
      url,
      ...config,
      body,
      method: 'PUT',
    }),
})

export default serviceWrapper
