import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

const ExposeSnackbarToWindow = () => {
  const snackbar = useSnackbar()

  useEffect(() => {
    window.snackbar = snackbar
  }, [snackbar])

  return null
}

export default ExposeSnackbarToWindow
