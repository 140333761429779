import { animate, useMotionValue } from 'framer-motion'
import { useEffect } from 'react'
import { createContainer } from 'unstated-next'
import useToggle from '../../../hooks/use-toggle'
import dimensions from '../../../utils/dimensions'
import navigationDimensions from '../config'

const MobileMenuStore = createContainer(() => {
  const [isOpen, toggle] = useToggle(false)
  const x = useMotionValue(0)

  useEffect(() => {
    const controls = animate(
      x,
      isOpen ? dimensions.vw(-navigationDimensions.menuOffsetMobile) : 0,
      { ease: [0.33, 1, 0.68, 1], duration: 0.5 },
    )

    return controls.stop
  }, [isOpen, x])

  return { x, isOpen, toggle }
})

export default MobileMenuStore
