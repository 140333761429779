export const mobileTabsButtonAnimationVariants = {
  active: {
    background: '#333',
  },
  inactive: {
    background: `rgba(250, 229, 219, 0)`,
  },
}

export const mobileTabsLabelAnimationVariants = {
  active: {
    width: 'auto',
  },
  inactive: {
    width: 0,
  },
}

export const mobileTransitionAnimation = {
  ease: [0.33, 1, 0.68, 1],
  duration: 0.3,
}
