import handleErrorNotification from './notification'

export const apiErrorHandler = (error, request) => {
  if (request?.payload?.errorNotification !== false) {
    handleErrorNotification(error)
  }
}

export const otherErrorHandler = (error, request) => {
  if (request?.payload?.errorNotification !== false) {
    handleErrorNotification(error)
  }
}
