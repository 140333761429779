import PropTypes from 'prop-types'
import { applySpec, pathOr, pathSatisfies, pipe, toUpper } from 'ramda'
import s3LinkParser from '../../utils/s3-link'

const UserProfile = applySpec({
  id: pathOr('', ['id']),
  picture: pipe(
    pathOr('', ['profile', 'profilePhoto', 'location']),
    s3LinkParser,
  ),
  name: pathOr('', ['profile', 'name']),
  email: pathOr('', ['email']),
  gender: pipe(pathOr('UNKNOWN', ['gender']), toUpper),
  phone: pathOr('', ['phoneNumber']),
  location: pathOr('', ['profile', 'city']),
  isTeacher: pathSatisfies(Boolean, ['roles', 'TEACHER']),
})

export const UserProfilePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  picture: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  gender: PropTypes.oneOf(['MALE', 'FEMALE', 'UNKNOWN']),
  phone: PropTypes.string,
  location: PropTypes.string,
  isTeacher: PropTypes.bool.isRequired,
})

export default UserProfile
