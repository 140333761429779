import { createContainer } from 'unstated-next'
import { useMemo } from 'react'
import ms from 'ms'
import AuthStore from '../../../../user/stores/auth-store'
import useSwr from '../../../../../hooks/use-swr'
import classroomParser from '../parser/classroom'
import useTime from '../../../../../hooks/use-time'

const MobileTabsStore = createContainer(() => {
  const { loggedIn } = AuthStore.useContainer()
  const now = useTime(5000)

  const { data: upcomingClasses } = useSwr({
    url: 'user/upcoming',
    initialData: [],
    parser: classroomParser,
    condition: loggedIn,
    server: 'sls-core',
  })

  const activeClasses = useMemo(() => upcomingClasses.length, [upcomingClasses])

  const ongoingClass = useMemo(
    () => upcomingClasses.find((c) => now > c.startTime - ms('30m')),
    [now, upcomingClasses],
  )

  return {
    activeClasses,
    ongoingClass,
  }
})

export default MobileTabsStore
