import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import Button from '../../../../../components/button'
import useToggle from '../../../../../hooks/use-toggle'
import AuthStore from '../../../../user/stores/auth-store'
import MobileMenuStore from '../../../stores/mobile-menu-store'
import { LogoutEl } from '../elements'

const Logout = () => {
  const [loggingOut, toggleLoggingOut] = useToggle(false)
  const { loggedIn, logout } = AuthStore.useContainer()
  const { toggle } = MobileMenuStore.useContainer()

  return !loggedIn ? null : (
    <LogoutEl>
      <Button
        disabled={loggingOut}
        endIcon={<ExitToAppIcon />}
        onClick={async () => {
          toggleLoggingOut(true)
          await logout()
          toggle(false)
          toggleLoggingOut(true)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }}
      >
        {loggingOut ? `Logging out...` : `Logout`}
      </Button>
    </LogoutEl>
  )
}

export default Logout
