import { useEffect } from 'react'
import { createContainer } from 'unstated-next'

const ServiceWorkerStore = createContainer(() => {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      navigator?.serviceWorker?.getRegistrations().then((registrations) => {
        if (!registrations.length) {
          // eslint-disable-next-line no-console
          console.log('No serviceWorker registrations found.')
          return
        }
        registrations.forEach((registration) => {
          registration.unregister().then((boolean) => {
            // eslint-disable-next-line no-console
            console.log(
              boolean ? 'Successfully unregistered' : 'Failed to unregister',
              `ServiceWorkerRegistration\n${
                registration.installing
                  ? `  .installing.scriptURL = ${registration.installing.scriptURL}\n`
                  : ''
              }${
                registration.waiting
                  ? `  .waiting.scriptURL = ${registration.waiting.scriptURL}\n`
                  : ''
              }${
                registration.active
                  ? `  .active.scriptURL = ${registration.active.scriptURL}\n`
                  : ''
              }  .scope: ${registration.scope}\n`,
            )
          })
        })
      })
    }
  }, [])

  return {}
})

export default ServiceWorkerStore
