import Link from 'next/link'
import logoWhite from '../../../../../assets/logo/logo-white-beta.svg'
import { LogoEl } from '../elements'
import TopBarStore from '../../../stores/top-bar-store'

const Logo = () => {
  const { hideIcons } = TopBarStore.useContainer()

  return (
    <Link href='/'>
      <a style={hideIcons ? { pointerEvents: 'none' } : {}}>
        <LogoEl src={logoWhite} alt='LivDemy Logo' />
      </a>
    </Link>
  )
}

export default Logo
