import isServer from '../../../utils/is-server'

const handleErrorNotification = (error) => {
  const {
    enqueueSnackbar = (message, options) => {
      // eslint-disable-next-line no-console
      console.log(`Error:`, message, options)
    },
    closeSnackbar = (key) => {
      // eslint-disable-next-line no-console
      console.log(`Close notification: ${key}`)
    },
  } = isServer() || !window ? {} : window?.snackbar

  if (error?.body?.error) {
    const key = enqueueSnackbar(error?.body?.error, {
      onClick: () => closeSnackbar(key),
      variant: 'error',
    })
  }

  error.body?.messages?.forEach((message) => {
    if (typeof message !== 'string') {
      return
    }
    const key = enqueueSnackbar(message, {
      onClick: () => closeSnackbar(key),
      variant: 'error',
    })
  })
}

export default handleErrorNotification
