import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { forwardRef, useCallback } from 'react'

import useToggle from '../../hooks/use-toggle'
import MixpanelTrackingStore from '../../modules/tracking/stores/mixpanel-tracking-store'
import AuthStore from '../../modules/user/stores/auth-store'
import { ButtonEl } from './elements'

const Button = forwardRef(
  (
    { children, disabled, onClick, requiresLogin, loadingComponent, ...props },
    ref,
  ) => {
    const router = useRouter()
    const [loading, toggleLoading] = useToggle(false)
    const { loggedIn } = AuthStore.useContainer()
    const { trackEvent } = MixpanelTrackingStore.useContainer()

    const handleClick = useCallback(
      async (e) => {
        if (requiresLogin && !loggedIn) {
          if (typeof requiresLogin === 'string') {
            const index = router.asPath.split('').findIndex((x) => x === '?')
            const url = router.asPath.slice(
              0,
              index === -1 ? router.asPath.length : index,
            )

            trackEvent(`redirecting to auth`, {
              action: requiresLogin,
              redirectTo: url,
            })

            return router.push(`/login?redirect=${url}?action=${requiresLogin}`)
          }
          return router.push(`/login`)
        }
        toggleLoading(true)
        await onClick(e)
        toggleLoading(false)

        return true
      },
      [requiresLogin, loggedIn, toggleLoading, onClick, router, trackEvent],
    )

    return (
      <ButtonEl
        ref={ref}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        disabled={disabled || loading}
        disableElevation
        onClick={handleClick}
      >
        {loadingComponent && loading ? loadingComponent : children}
      </ButtonEl>
    )
  },
)

export default Button

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  loadingComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  requiresLogin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  theme: PropTypes.oneOf(['primary', 'secondary', 'text', 'disabled']),
}

Button.defaultProps = {
  disabled: false,
  loadingComponent: '',
  onClick: () => {},
  requiresLogin: false,
  theme: 'text',
}
