import { AnimatePresence } from 'framer-motion'
import Head from 'next/head'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import isServer from '../../utils/is-server'
import withStore from '../../utils/with-store'
import MobileMenu from './components/mobile-menu'
import MobileTabs from './components/mobile-tabs'
import MobileTopBar from './components/mobile-top-bar'
import { ContentEl, MainEl, MenuOverlayEl, NavigationEl } from './elements'
import MobileMenuStore from './stores/mobile-menu-store'
import TopBarStore from './stores/top-bar-store'

const Navigation = ({ children }) => {
  const { asPath } = useRouter()
  const { x, isOpen, toggle } = MobileMenuStore.useContainer()

  // noinspection HtmlRequiredTitleElement
  return (
    <NavigationEl>
      <Head>
        <meta
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
          name='viewport'
        />
      </Head>
      <MobileMenu />
      <MainEl style={{ x }}>
        <MobileTopBar />
        <AnimatePresence exitBeforeEnter>
          <ContentEl
            key={asPath}
            animate='show'
            exit='hidden'
            initial={isServer() ? 'show' : 'hidden'}
            variants={{
              hidden: {
                opacity: 0,
              },
              show: {
                opacity: 1,
              },
            }}
          >
            {children}
          </ContentEl>
        </AnimatePresence>
        <MobileTabs />
      </MainEl>
      {isOpen && <MenuOverlayEl onClick={() => toggle(false)} style={{ x }} />}
    </NavigationEl>
  )
}

export default withStore([MobileMenuStore, TopBarStore])(Navigation)

Navigation.propTypes = {
  children: PropTypes.element.isRequired,
}
