import Head from 'next/head'

const key = process.env.NEXT_PUBLIC_CRAZY_EGG_KEY

const CrazyEgg = () => {
  // noinspection HtmlRequiredTitleElement
  return key ? (
    <Head>
      <script
        async='async'
        src={`//script.crazyegg.com/pages/scripts${key}.js`}
        type='text/javascript'
      />
    </Head>
  ) : null
}

export default CrazyEgg
