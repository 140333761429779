import { useCallback, useEffect, useRef } from 'react'
import { createContainer } from 'unstated-next'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/browser'
import useToggle from '../../../hooks/use-toggle'
import ProfileStore from '../../user/stores/profile-store'

const Mixpanel = require('mixpanel-browser')

const MixpanelTrackingStore = createContainer(() => {
  const SendInBlue = useRef({
    track: () => {},
    identify: () => {},
  })
  const router = useRouter()
  const { loading, data: user = {} } = ProfileStore.useContainer()
  const [mixpanelInitialized, toggleMixpanelInitialized] = useToggle(false)
  const [sendInBlueInitialized, toggleSendInBlueInitialized] = useToggle(false)

  useEffect(() => {
    if (loading) {
      return () => {}
    }

    const MIXPANEL_KEY = process.env.NEXT_PUBLIC_MIXPANEL_KEY

    if (MIXPANEL_KEY) {
      if (!mixpanelInitialized) {
        Mixpanel.init(MIXPANEL_KEY, { debug: true })

        if (user?.id) {
          Mixpanel.identify(user?.id)

          Mixpanel.people.set({
            $email: user?.email,
            name: user?.name,
          })
        }

        toggleMixpanelInitialized(true)
      } else if (mixpanelInitialized && user?.id) {
        Mixpanel.identify(user?.id)

        Mixpanel.people.set({
          $email: user?.email,
          name: user?.name,
        })
      }
    }

    return () => {}
  }, [mixpanelInitialized, loading, toggleMixpanelInitialized, user])

  useEffect(() => {
    if (loading) {
      return () => {}
    }

    const SENDINBLUE_KEY = process.env.NEXT_PUBLIC_SENDINBLUE_KEY

    if (SENDINBLUE_KEY) {
      if (!sendInBlueInitialized) {
        const interval = setInterval(() => {
          SendInBlue.current = window.sendinblue

          if (window.sendinblue) {
            if (user?.id) {
              SendInBlue.current.identify(user?.email, user)
            }

            clearInterval(interval)
          }
        }, 1000)

        toggleSendInBlueInitialized(true)
      } else if (sendInBlueInitialized && user?.id) {
        SendInBlue.current.identify(user?.email, user)
      }
    }

    return () => {}
  }, [loading, sendInBlueInitialized, toggleSendInBlueInitialized, user])

  const trackEvent = useCallback(
    (eventName, meta) => {
      if (mixpanelInitialized) {
        Mixpanel?.track(eventName, meta, { send_immediately: true })
      }
      if (sendInBlueInitialized) {
        SendInBlue.current.track(eventName, meta)
      }
    },
    [mixpanelInitialized, sendInBlueInitialized],
  )

  useEffect(() => {
    if (mixpanelInitialized) {
      try {
        const { pathname, search } = new URL(
          router.asPath,
          window.location.origin,
        )

        Mixpanel?.track(
          'page-view',
          { route: pathname, query: search },
          { send_immediately: true },
        )
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    if (sendInBlueInitialized) {
      try {
        const { pathname, search } = new URL(
          router.asPath,
          window.location.origin,
        )

        SendInBlue.current?.track('page-view', {
          route: pathname,
          query: search,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }, [mixpanelInitialized, router.asPath, sendInBlueInitialized])

  return { trackEvent }
})

export default MixpanelTrackingStore
