import { IconButton } from '@material-ui/core'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import Link from 'next/link'
import AuthStore from '../../../../user/stores/auth-store'
import { NotificationsEl } from '../elements'
import TopBarStore from '../../../stores/top-bar-store'

const Notifications = () => {
  const { loggedIn } = AuthStore.useContainer()
  const { hideIcons } = TopBarStore.useContainer()

  return !loggedIn || hideIcons ? null : (
    <NotificationsEl>
      <Link href='/notifications'>
        <a>
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
        </a>
      </Link>
    </NotificationsEl>
  )
}

export default Notifications
