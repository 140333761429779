import { useState } from 'react'
import { useToggle } from 'react-use'
import { createContainer } from 'unstated-next'

const TopBarStore = createContainer(() => {
  const [dark, toggleDark] = useToggle(false)
  const [hideIcons, toggleHideIcons] = useToggle(false)
  const [backUrl, setBackUrl] = useState('')

  return { dark, backUrl, hideIcons, toggleDark, setBackUrl, toggleHideIcons }
})

export default TopBarStore
