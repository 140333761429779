import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'

const socialLinks = [
  {
    icon: <YouTubeIcon />,
    id: 'yt',
    link: 'https://www.youtube.com/c/LivDemy',
  },
  {
    icon: <FacebookIcon />,
    id: 'fb',
    link: 'https://www.facebook.com/LivDemy',
  },
  {
    icon: <TwitterIcon />,
    id: 'tw',
    link: 'https://twitter.com/LivDemy',
  },
  {
    icon: <InstagramIcon />,
    id: 'in',
    link: 'https://www.instagram.com/LivDemy/',
  },
]

export default socialLinks
