import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import MobileMenuStore from '../../../stores/mobile-menu-store'
import { MenuToggleEl } from '../elements'
import TopBarStore from '../../../stores/top-bar-store'

const MenuToggle = () => {
  const { isOpen, toggle } = MobileMenuStore.useContainer()
  const { hideIcons } = TopBarStore.useContainer()

  return hideIcons ? null : (
    <MenuToggleEl>
      <IconButton onClick={() => toggle()}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </MenuToggleEl>
  )
}

export default MenuToggle
