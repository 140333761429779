import Back from './components/back'
import LivdemyPro from './components/livdemy-pro'
import Logo from './components/logo'
import MenuToggle from './components/menu-toggle'
import Notifications from './components/notifications'
import { MobileTopBarEl } from './elements'

const MobileTopBar = () => {
  return (
    <MobileTopBarEl animate='show-back'>
      <Back />
      <Logo />
      <div className='spacer' />
      <LivdemyPro />
      <Notifications />
      <MenuToggle />
    </MobileTopBarEl>
  )
}

export default MobileTopBar
