import PropTypes from 'prop-types'
import withStore from '../../utils/with-store'
import RealtimeStore from './stores/realtime-store'

const Realtime = ({ children }) => {
  return children
}

Realtime.propTypes = {
  children: PropTypes.element.isRequired,
}

export default withStore([RealtimeStore])(Realtime)
