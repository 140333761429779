import { IconButton } from '@material-ui/core'

import socialLinks from '../config/social'
import { SocialEl } from '../elements'

const Social = () => {
  return (
    <SocialEl>
      {socialLinks.map(({ id, icon, link }) => (
        <a key={`social-${id}`} href={link} rel='noreferrer' target='_blank'>
          <IconButton>{icon}</IconButton>
        </a>
      ))}
    </SocialEl>
  )
}

export default Social
