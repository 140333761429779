import { omit } from 'ramda'
import { useCallback, useState } from 'react'

const useMap = (initialState = {}) => {
  const [map, update] = useState(initialState)

  const set = useCallback((key, value) => {
    update((oldMap) =>
      typeof value === 'function'
        ? { ...oldMap, [key]: value(oldMap[key], oldMap) }
        : { ...oldMap, [key]: value },
    )
  }, [])

  const setAll = useCallback((value) => {
    if (!['object', 'function'].includes(typeof value)) {
      return
    }
    update((oldMap) => (typeof value === 'function' ? value(oldMap) : value))
  }, [])

  const remove = useCallback((key) => {
    update((oldMap) => {
      return omit([key])(oldMap)
    })
  }, [])

  const reset = useCallback(() => {
    update(initialState)
  }, [initialState])

  return [
    map,
    {
      set,
      setAll,
      remove,
      reset,
    },
  ]
}

export default useMap
