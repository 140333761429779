import { capitalize } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  always,
  applySpec,
  equals,
  ifElse,
  join,
  juxt,
  multiply,
  pathOr,
  pipe,
  propOr,
  toLower,
} from 'ramda'
import getCurrencySymbol from '../../utils/currency-symbol'

const SubscriptionActive = applySpec({
  id: propOr('', 'id'),
  modelId: propOr('', 'subscriptionModelId'),
  name: pipe(propOr('', 'subscriptionLevel'), toLower, capitalize),
  amount: propOr('', 'amount'),
  currency: propOr('', 'currency'),
  frequency: propOr('ANNUAL', 'frequency'),
  status: propOr('', 'status'),
  startTime: pathOr(0, ['currentSubscriptionTimePeriod', 'startTime']),
  endTime: pathOr(0, ['currentSubscriptionTimePeriod', 'endTime']),
  isCancelled: pathOr(false, ['recurringSubscriptionPayment', 'isCancelled']),
  features: applySpec({
    broadcastClasses: pipe(
      propOr(0, 'liveClassLimit'),
      ifElse(equals(-1), always('Unlimited'), String),
    ),
    remainingUnlocks: pipe(
      propOr(0, 'remainingUnlocks'),
      ifElse(equals(-1), always('Unlimited'), String),
    ),
    lobby: propOr(true, 'lobby'),
    priorityNotifications: propOr(true, 'priorityNotifications'),
    groupChat: pathOr(false, ['grants', 'groupChat']),
    liveChat: pathOr(false, ['grants', 'liveChat']),
    recordings: pathOr(false, ['grants', 'previousRecordingArchive']),
    discountBc: pathOr(0, ['discounts', 'broadcastClass']),
    discountCourse: pathOr(0, ['discounts', 'course']),
    discountRecorded: pathOr(0, ['discounts', 'recordedCourse']),
    duration: always(12),
    pricing: pipe(
      juxt([
        pipe(pathOr(0, ['currency']), getCurrencySymbol),
        pipe(
          pathOr(0, ['amount']),
          multiply(1 / 12),
          (x) => Math.floor(x),
          String,
        ),
        always('/month'),
      ]),
      join(''),
    ),
  }),
})

export const SubscriptionActivePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  features: PropTypes.shape({
    broadcastClasses: PropTypes.string.isRequired,
    lobby: PropTypes.bool.isRequired,
    priorityNotifications: PropTypes.bool.isRequired,
    groupChat: PropTypes.bool.isRequired,
    liveChat: PropTypes.bool.isRequired,
    recordings: PropTypes.bool.isRequired,
  }).isRequired,
})

export default SubscriptionActive
