import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import useToggle from '../hooks/use-toggle'
import setupPluralize from '../utils/setup-pluralize'
import ErrorFallback from './error-boundary/fallback'
import Navigation from './navigation'
import Notifications from './notifications'
import PaymentCoupon from './payment-coupon'
import Realtime from './realtime'
import ServiceWorkerHandler from './service-worker'
import CustomSwr from './swr'
import Tracking from './tracking'
import UserWrapper from './user'

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        opacity: `1 !important`,
      },
    },
    MuiIconButton: {
      root: {
        opacity: `1 !important`,
      },
    },
    MuiStepButton: {
      root: {
        opacity: `1 !important`,
      },
    },
  },
  typography: {
    fontFamily: ['HomepageBaukasten', 'sans-serif'].join(','),
  },
})

const Modules = ({ children }) => {
  const [crashed, toggleCrashed] = useToggle(false)

  useEffect(() => {
    setupPluralize()
  }, [])

  return (
    <>
      <ServiceWorkerHandler />
      <PaymentCoupon>
        <CustomSwr>
          <ThemeProvider theme={theme}>
            <Notifications>
              <UserWrapper>
                <Tracking>
                  <Navigation>
                    <ErrorBoundary
                      FallbackComponent={ErrorFallback}
                      onReset={() => {
                        toggleCrashed(false)
                      }}
                      resetKeys={[crashed]}
                    >
                      <Realtime>{children}</Realtime>
                    </ErrorBoundary>
                  </Navigation>
                </Tracking>
              </UserWrapper>
            </Notifications>
          </ThemeProvider>
        </CustomSwr>
      </PaymentCoupon>
    </>
  )
}

export default Modules

Modules.propTypes = {
  children: PropTypes.element.isRequired,
}
