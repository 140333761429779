import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'

import ExposeSnackbarToWindow from './components/expose-snackbar-to-window'
import SnackBarStyles from './styles'
import useSnackbarStyles from './styles/use-snackbar-styles'

const Notifications = ({ children }) => {
  const classes = useSnackbarStyles()

  return (
    <>
      <SnackBarStyles />
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        autoHideDuration={3000}
        classes={classes}
        iconVariant={{
          info: <MailOutlineIcon style={{ marginRight: '0.5em' }} />,
        }}
      >
        <>
          <ExposeSnackbarToWindow />
          {children}
        </>
      </SnackbarProvider>
    </>
  )
}

Notifications.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Notifications
