import localforage from 'localforage'
import { USER_TOKENS_LOCAL_STORE_KEY } from '../../../modules/user/config'
import corePublic from './corePublic'
import refresh from './refresh'

const getAuthToken = () =>
  localforage
    .getItem(USER_TOKENS_LOCAL_STORE_KEY)
    .then((value) => (value?.auth ? `Bearer ${value.auth}` : ``))
    .catch(() => ``)

const coreProtected = corePublic.extend({
  hooks: {
    before: async ({ payload, next }) => {
      const accessToken = await getAuthToken()

      next({
        ...payload,
        headers: accessToken
          ? {
              ...payload.headers,
              Authorization: `${accessToken}`,
            }
          : payload.headers,
      })
    },

    fail: async ({ payload, result, retry, next }) => {
      if (result.status === 401) {
        const {
          success,
          result: { authToken },
        } = await refresh.doSingleRequest({})

        if (success) {
          const accessToken = `Bearer ${authToken}`

          return retry({
            ...payload,
            headers: {
              ...payload.headers,
              Authorization: `${accessToken}`,
            },
          })
        }
        return next(result)
      }

      return next(result)
    },
  },
})

export default coreProtected
