const navigationDimensions = {
  sideBarWidth: 0, // 3.5em
  topBarHeight: 0, // 3.5 em
  topBarHeightMobile: 5, // em
  tabsHeightMobile: 4, // em
  menuOffsetMobile: 66, // vw
  mobileTopBarZIndex: 99999,
}

export default navigationDimensions
