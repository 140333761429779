import ms from 'ms'
import PropTypes from 'prop-types'
import { identity } from 'ramda'
import { SWRConfig } from 'swr'

import getApiService from './utils/get-api-service'

const CustomSwr = ({ children }) => {
  return (
    <SWRConfig
      value={{
        dedupingInterval: ms('5m'),
        errorRetryCount: 3,
        errorRetryInterval: ms('30s'),
        fetcher: (
          url,
          parser = identity,
          server = 'main',
          userId,
          name,
          errorNotification,
        ) =>
          getApiService(server)
            .get(url, { errorNotification })
            .then((response) =>
              response.success ? response : Promise.reject(response.result),
            )
            .then((data) => data.result)
            .then(parser)
            .catch((e) => Promise.reject(e)),
        focusThrottleInterval: ms('30s'),
        refreshInterval: ms('5m'),
        revalidateOnMount: true,
      }}
    >
      {children}
    </SWRConfig>
  )
}

CustomSwr.propTypes = {
  children: PropTypes.element.isRequired,
}

export default CustomSwr
