import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { useEffect } from 'react'
import { createContainer } from 'unstated-next'

import useToggle from '../../../hooks/use-toggle'
import AuthStore from '../../user/stores/auth-store'
import ProfileStore from '../../user/stores/profile-store'

const ErrorLoggerStore = createContainer(() => {
  const [loaded, setLoaded] = useToggle(false)

  const { loggedIn } = AuthStore.useContainer()
  const {
    data: { id, name: username, email } = {},
  } = ProfileStore.useContainer()

  useEffect(() => {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: 'production',
      integrations: [new Integrations.BrowserTracing()],
      release: 'livdemy-frontend',
      tracesSampleRate: 0.1,
    })
    setLoaded(true)
  }, [setLoaded])

  useEffect(() => {
    if (loggedIn) {
      Sentry.setUser({ email, id, username })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [email, id, loggedIn, username])

  return { loaded }
})

export default ErrorLoggerStore
