import Api from '../../../api/configuration'

export const SUBSCRIPTION_INVITE_TEMPLATE = `SUBSCRIPTION_INVITATION_JUL_05`

const PricingApis = {
  purchaseSubscription: (subscriptionModelId) =>
    Api.coreProtected.post(`subscription/purchase`, { subscriptionModelId }),
  sendSubscriptionInvite: (email) =>
    Api.coreProtected.post(`invitations`, {
      templateType: SUBSCRIPTION_INVITE_TEMPLATE,
      userEmail: email,
    }),
  deleteSubscriptionInvite: (id) =>
    Api.coreProtected.delete(
      `invitation/${SUBSCRIPTION_INVITE_TEMPLATE}/${id}`,
    ),
}

export default PricingApis
