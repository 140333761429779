import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import ReactGA from 'react-ga'
import { createContainer } from 'unstated-next'

import useToggle from '../../../hooks/use-toggle'
import AuthStore from '../../user/stores/auth-store'
import ProfileStore from '../../user/stores/profile-store'

const trackers = ['default-tracker']

const GoogleAnalyticsTrackingStore = createContainer(() => {
  const router = useRouter()
  const { analyticsTrackers } = AuthStore.useContainer()
  const { loading, data: { id: userId } = {} } = ProfileStore.useContainer()
  const [initialized, toggleInitialized] = useToggle(false)

  useEffect(() => {
    if (loading) {
      return () => {}
    }

    const handleRouteChange = (url) => {
      ReactGA.set({ page: url }, trackers)
      ReactGA.pageview(url, trackers)
    }

    if (!initialized) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKER_ID, {
        gaOptions: {
          alwaysSendReferrer: true,
          siteSpeedSampleRate: 100,
          userId,
        },
      })

      router.events.on('routeChangeComplete', handleRouteChange)

      toggleInitialized(true)
    } else {
      ReactGA.set({ userId }, trackers)
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, loading, toggleInitialized, userId])

  const logEvent = useCallback(
    ({ category = '', action = '', label = '', nonInteraction = true }) => {
      if (initialized) {
        ReactGA.event({ action, category, label, nonInteraction }, trackers)
      }
    },
    [initialized],
  )

  useEffect(() => {
    analyticsTrackers.current = {
      ...analyticsTrackers.current,
      ga: logEvent,
    }
  }, [analyticsTrackers, logEvent])

  return { logEvent }
})

export default GoogleAnalyticsTrackingStore
