import { assoc } from 'ramda'
import Api from '../../../api/configuration'
import UserProfile from '../../../schemas/user/profile'

const ProfileApis = {
  updateProfile: ({ name, location, phone, gender }) =>
    Api.coreProtected
      .put('profile', { name, city: location, phoneNumber: phone, gender })
      .then((response) =>
        assoc('result', UserProfile(response.result), response),
      ),
}

export default ProfileApis
