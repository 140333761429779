import localforage from 'localforage'
import { applySpec, head, join, juxt, pathOr, pipe, split } from 'ramda'

import Api from '../configuration'

const IP_INFO_KEY = 'USER_IP_INFO'

const ipInfo = async () => {
  const info = await localforage.getItem(IP_INFO_KEY).catch(() => false)

  if (info) {
    return info
  }

  const newInfo = await Api.base.get('https://ipapi.co/json').then(
    applySpec({
      callingCode: pathOr('+91', ['result', 'country_calling_code']),
      country: pathOr('IN', ['result', 'country_code']),
      currency: pathOr('INR', ['result', 'currency']),
      language: pipe(
        pathOr(navigator?.language || navigator?.languages[0] || 'en-IN', [
          'result',
          'languages',
        ]),
        split(','),
        head,
      ),
      location: pipe(
        juxt([
          pathOr('', ['result', 'city']),
          pathOr('', ['result', 'region']),
          pathOr('', ['result', 'country_name']),
        ]),
        join(', '),
      ),
      timeZone: pathOr(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone, [
        'result',
        'timezone',
      ]),
    }),
  )

  await localforage.setItem(IP_INFO_KEY, newInfo)

  return newInfo
}

export default ipInfo
