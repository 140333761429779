import * as localforage from 'localforage'
import { USER_PREFERENCES_KEY } from '../../../app/dashboard/settings/preferences/stores/peferences-store'
import isServer from '../../../utils/is-server'
import ipInfo from '../../misc/ip-info'
import base from './base'

const url = process.env.NEXT_PUBLIC_CACHE_SERVER_API_URL

const corePublic = base.extend({
  url,
  hooks: {
    before: async ({ payload, next }) => {
      const localCurrency = isServer()
        ? 'INR'
        : (await localforage
            .getItem(USER_PREFERENCES_KEY)
            .then((pref) => pref?.currency)) || (await ipInfo())?.currency

      const updatedHeaders = {
        ...payload.headers,
        'local-currency': localCurrency,
      }

      return next({ ...payload, headers: updatedHeaders })
    },
    done: async ({ result, next }) => {
      return next(result.data)
    },
  },
})

export default corePublic
