import {
  always,
  applySpec,
  ascend,
  descend,
  equals,
  filter,
  head,
  identity,
  ifElse,
  isNil,
  last,
  map,
  pathOr,
  pipe,
  propEq,
  propOr,
  sort,
} from 'ramda'
import SubscriptionActive from '../../../../schemas/subscription/active'
import SubscriptionModel from '../../../../schemas/subscription/model'
import addId from '../../../../utils/add-id'
import { SUBSCRIPTION_INVITE_TEMPLATE } from '../../apis'

const SubscriptionsParser = pipe(
  map(SubscriptionModel),
  sort(ascend(propOr(0, 'amount'))),
)

export const ActiveSubscriptionParser = pipe(
  filter(propEq('status', 'ACTIVE')),
  head,
  ifElse(isNil, always({}), identity),
  SubscriptionActive,
)

export const SubscriptionInvitationsParser = applySpec({
  received: pipe(
    propOr([], 'receivedInvitations'),
    filter(propEq('template_type', SUBSCRIPTION_INVITE_TEMPLATE)),
    last,
    applySpec({
      id: propOr('', 'invitation_id'),
      type: propOr('', 'template_type'),
      status: propOr('', 'status'),
      share_limit: propOr('', 'limit'),
      sender: pipe(
        propOr('LivDemy', 'parent_email'),
        ifElse(equals('admin@livdemy.com'), always('LivDemy'), identity),
      ),
    }),
  ),
  sent: pipe(
    propOr([], 'sentInvitations'),
    filter(propEq('template_type', SUBSCRIPTION_INVITE_TEMPLATE)),
    map(
      applySpec({
        id: propOr('', 'invitation_id'),
        type: propOr('', 'template_type'),
        status: propOr('', 'status'),
        email: propOr('LivDemy', 'child_email'),
      }),
    ),
  ),
})

export const pricingReviewsParser = pipe(
  addId,
  sort(descend(pathOr(0, ['text', 'length']))),
)

export default SubscriptionsParser
