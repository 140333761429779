import Link from 'next/link'

import { ActiveSubscriptionParser } from '../../../../../app/pricing/home/utils/parser'
import useSwr from '../../../../../hooks/use-swr'
import AuthStore from '../../../../user/stores/auth-store'
import { LivDemyProEl } from '../elements'

const LivdemyPro = () => {
  const { loggedIn } = AuthStore.useContainer()

  const { data: existing } = useSwr({
    condition: loggedIn,
    parser: ActiveSubscriptionParser,
    url: `subscriptions`,
  })

  return existing?.id ? null : (
    <Link href='/pricing' passHref>
      <a>
        <LivDemyProEl style={{}} theme='secondary'>
          LivDemy Pro
        </LivDemyProEl>
      </a>
    </Link>
  )
}

export default LivdemyPro
