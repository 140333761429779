import { Badge, IconButton } from '@material-ui/core'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Text } from '../../../../styles/typography'
import mobileTabs from './config/tabs'
import {
  mobileTabsButtonAnimationVariants,
  mobileTabsLabelAnimationVariants,
  mobileTransitionAnimation,
} from './animation'
import { MobileTabsEl } from './elements'
import MixpanelTrackingStore from '../../../tracking/stores/mixpanel-tracking-store'
import withStore from '../../../../utils/with-store'
import MobileTabsStore from './stores/mobile-tabs-store'

const MobileTabs = () => {
  const { asPath } = useRouter()
  const { trackEvent } = MixpanelTrackingStore.useContainer()
  const { activeClasses } = MobileTabsStore.useContainer()

  return (
    <MobileTabsEl>
      {mobileTabs({ asPath, activeClasses }).map(
        ({ id, name, icon, route, isActive, counter }) => (
          <Link key={id} href={route} passHref>
            <motion.a
              className={isActive() ? 'active' : ''}
              animate={isActive() ? 'active' : 'inactive'}
              initial='inactive'
              variants={mobileTabsButtonAnimationVariants}
              transition={mobileTransitionAnimation}
              onClick={() =>
                trackEvent('mobile-bottom-tab-clicked', { id, name, route })
              }
            >
              <Badge badgeContent={counter}>
                <IconButton>{icon}</IconButton>
                <Text
                  as={motion.p}
                  variants={mobileTabsLabelAnimationVariants}
                  transition={mobileTransitionAnimation}
                >
                  {name}
                </Text>
              </Badge>
            </motion.a>
          </Link>
        ),
      )}
    </MobileTabsEl>
  )
}

export default withStore([MobileTabsStore])(MobileTabs)
