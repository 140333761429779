import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { isDesktop } from 'react-device-detect'

import isAmpRoute from '../config/amp-routes'
import Modules from '../modules'
import GlobalStyles from '../styles/global'

function App({ Component, pageProps }) {
  const { route, asPath } = useRouter()

  useEffect(() => {
    if (isDesktop) {
      window.location = `https://livdemy.com${asPath}`
    }
  }, [asPath])

  // noinspection HtmlRequiredTitleElement
  return (
    <>
      <GlobalStyles />
      {isAmpRoute(route) ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...pageProps} />
      ) : (
        <Modules>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </Modules>
      )}
    </>
  )
}

export default App

App.propTypes = {
  Component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
}
