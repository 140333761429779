import styled, { css } from 'styled-components'

const basicType = css``

const generalCustomizations = (props) => css`
  font-weight: ${props.bold ? 'bold' : props.medium ? '500' : 'normal'};
`

export const H1 = styled.h1`
  ${basicType};
  ${generalCustomizations};
  font-size: 3.052em;
`

export const H2 = styled.h2`
  ${basicType};
  ${generalCustomizations};
  font-size: 2.441em;
`

export const H3 = styled.h3`
  ${basicType};
  ${generalCustomizations};
  font-size: 1.953em;
`

export const H4 = styled.h4`
  ${basicType};
  ${generalCustomizations};
  font-size: 1.563em;
`

export const H5 = styled.h5`
  ${basicType};
  ${generalCustomizations};
  font-size: 1.25em;
`

export const H6 = styled.h6`
  ${basicType};
  ${generalCustomizations};
  font-size: 1.125em;
`

export const Text = styled.p`
  ${basicType};
  ${generalCustomizations};
  font-size: 1em;
`

export const S05 = styled.p`
  ${basicType};
  ${generalCustomizations};
  font-size: 0.88888em;
`

export const S1 = styled.p`
  ${basicType};
  ${generalCustomizations};
  font-size: 0.8em;
`

export const S2 = styled.p`
  ${basicType};
  ${generalCustomizations};
  font-size: 0.64em;
`

export const S3 = styled.p`
  ${basicType};
  ${generalCustomizations};
  font-size: 0.512em;
`
