import localforage from 'localforage'
import { pipe, propOr } from 'ramda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { cache } from 'swr'
import { createContainer } from 'unstated-next'

import { USER_PREFERENCES_KEY } from '../../../app/dashboard/settings/preferences/stores/peferences-store'
import useToggle from '../../../hooks/use-toggle'
import AuthApis from '../apis/auth'
import { USER_TOKENS_LOCAL_STORE_KEY } from '../config'

const AuthStore = createContainer(() => {
  const analyticsTrackers = useRef({
    ga: () => {},
  })
  const [loading, toggleLoading] = useToggle(true)

  const [tokens, updateTokens] = useState(null)

  const loggedIn = useMemo(() => Boolean(tokens), [tokens])

  useEffect(() => {
    localforage.getItem(USER_TOKENS_LOCAL_STORE_KEY).then((value) => {
      updateTokens(value)
      toggleLoading(false)
    })
  }, [toggleLoading])

  const login = useCallback(async (newTokens) => {
    await localforage.setItem(USER_TOKENS_LOCAL_STORE_KEY, newTokens)
    updateTokens(newTokens)
  }, [])

  const passwordLogin = useCallback(
    async (email, password) => {
      try {
        const { success, result } = await AuthApis.passwordLogin(
          email,
          password,
        )

        if (success) {
          await login(result)
          analyticsTrackers.current.ga({
            action: 'authenticate',
            category: 'conversion',
            label: 'login',
          })
        }

        return success
      } catch (e) {
        // Todo: capture exception to sentry
        return false
      }
    },
    [login],
  )

  const register = useCallback(
    async (name, email, password) => {
      try {
        const { success, result } = await AuthApis.register(
          name,
          email,
          password,
        )

        if (success) {
          await login(result)
          analyticsTrackers.current.ga({
            action: 'authenticate',
            category: 'conversion',
            label: 'signup',
          })
        }

        return success
      } catch (e) {
        // Todo: capture exception to sentry
        return false
      }
    },
    [login],
  )

  const googleAuth = useCallback(
    (isRegistration) => async (response) => {
      try {
        const { success, result } = await pipe(
          propOr('', 'tokenId'),
          AuthApis.googleAuth,
        )(response)

        if (success) {
          await login(result)
          analyticsTrackers.current.ga({
            action: 'authenticate',
            category: 'conversion',
            label: isRegistration ? 'signup' : 'login',
          })
        }

        return success
      } catch (e) {
        // Todo: capture exception to sentry
        return false
      }
    },
    [login],
  )

  const fbAuth = useCallback(
    (isRegistration) => async (response) => {
      try {
        const { success, result } = await pipe(
          propOr('', 'accessToken'),
          AuthApis.fbAuth,
        )(response)

        if (success) {
          await login(result)
          analyticsTrackers.current.ga({
            action: 'authenticate',
            category: 'conversion',
            label: isRegistration ? 'signup' : 'login',
          })
        }

        return success
      } catch (e) {
        // Todo: capture exception to sentry
        return false
      }
    },
    [login],
  )

  const logout = useCallback(async () => {
    await localforage.removeItem(USER_TOKENS_LOCAL_STORE_KEY)
    await localforage.removeItem(USER_PREFERENCES_KEY)

    const keys = await localforage.keys()
    const swrKeys = keys.filter((key) => key.startsWith('swr-'))

    await Promise.all(swrKeys.map((key) => localforage.removeItem(key)))

    cache.clear()

    window.localStorage.removeItem('user-profile-data')
    window.localStorage.removeItem('user-teacher-profile-data')

    window.location.reload()
  }, [])

  return {
    analyticsTrackers,
    fbAuth,
    googleAuth,
    loading,
    loggedIn,
    logout,
    passwordLogin,
    register,
    tokens,
  }
})

export default AuthStore
