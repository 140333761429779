import { makeStyles } from '@material-ui/core'

const useSnackbarStyles = makeStyles(({ palette }) => ({
  variantSuccess: {
    backgroundColor: '#4ede92 !important',
  },
  variantError: {
    backgroundColor: '#FF4E43 !important',
  },
  variantInfo: {
    backgroundColor: '#3c91e6 !important',
  },
  variantWarning: {
    backgroundColor: palette.warning.main,
  },
}))

export default useSnackbarStyles
