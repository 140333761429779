import Link from 'next/link'
import { useRouter } from 'next/router'

import { Text } from '../../../../../styles/typography'
import MobileMenuStore from '../../../stores/mobile-menu-store'
import links from '../config/links'
import { LinksEl } from '../elements'

const Links = () => {
  const { asPath } = useRouter()
  const { toggle } = MobileMenuStore.useContainer()

  return (
    <LinksEl>
      {links.map(({ id: sectionId, items }) => (
        <div key={`section-${sectionId}`} className='section'>
          {items.map(({ id: itemId, route, name, icon }) => (
            <Link key={`link-${itemId}`} href={route}>
              <a className={asPath.startsWith(route) ? 'active' : ''}>
                <Text onClick={() => toggle(false)}>
                  {name}
                  {icon}
                </Text>
              </a>
            </Link>
          ))}
        </div>
      ))}
    </LinksEl>
  )
}

export default Links
