/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import Button from '../../../../../components/button'
import { accent, background0, background1 } from '../../../../../styles/colors'
import { hFlex } from '../../../../../styles/style-helpers'
import navigationDimensions from '../../../config'

export const MobileTopBarEl = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${navigationDimensions.topBarHeightMobile}em;
  ${hFlex};
  padding: 0 0 0 1em;
  z-index: ${navigationDimensions.mobileTopBarZIndex};
  background: ${({ dark }) => (dark ? background0 : background1)};

  .spacer {
    flex: 1;
  }

  @media screen and (min-device-width: 769px) {
    display: none;
  }
`

export const BackEl = styled(motion.div)`
  height: ${navigationDimensions.topBarHeightMobile * 0.5}em;
  ${hFlex};
  overflow: hidden;
  margin-right: 0.25em;

  button {
    padding: 0;
    color: #232323;
  }
`

export const LogoEl = styled.img`
  width: 22.5vw;
  max-height: ${navigationDimensions.topBarHeightMobile * 0.5}em;
  transform: translateY(-7.5%);
`

export const NotificationsEl = styled.div`
  button {
    color: #232323;
    padding: 1em 0.5em;

    svg {
      font-size: 1.25em;
    }
  }
`

export const MenuToggleEl = styled.div`
  button {
    color: #232323;
    padding: 1em 0.5em;

    svg {
      font-size: 1.25em;
    }
  }
`

export const LivDemyProEl = styled(Button)`
  border-color: ${accent} !important;
  padding: 0 0.75em !important;

  .MuiButton-label {
    color: ${accent};
  }
`
