import PropTypes from 'prop-types'
import withStore from '../../utils/with-store'
import Pixel from './components/pixel'
import GoogleAnalyticsTrackingStore from './stores/google-analytics-tracking-store'
import PixelTrackingStore from './stores/pixel-tracking-store'
import MixpanelTrackingStore from './stores/mixpanel-tracking-store'
import Sendinblue from './components/sendinblue'
import Segment from './components/segment'
import CrazyEgg from './components/crazy-egg'

const Tracking = ({ children }) => {
  return (
    <>
      <Segment />
      <Pixel />
      <Sendinblue />
      <CrazyEgg />
      {children}
    </>
  )
}

Tracking.propTypes = {
  children: PropTypes.element.isRequired,
}

export default withStore([
  MixpanelTrackingStore,
  GoogleAnalyticsTrackingStore,
  PixelTrackingStore,
])(Tracking)
