import PersonIcon from '@material-ui/icons/Person'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { H6, S1, Text } from '../../../../../styles/typography'
import dimensions from '../../../../../utils/dimensions'
import AuthStore from '../../../../user/stores/auth-store'
import ProfileStore from '../../../../user/stores/profile-store'
import MobileMenuStore from '../../../stores/mobile-menu-store'
import { UserInfoEl } from '../elements'

const UserInfo = () => {
  const router = useRouter()
  const { loggedIn } = AuthStore.useContainer()
  const { data: { name, email, picture } = {} } = ProfileStore.useContainer()
  const { toggle } = MobileMenuStore.useContainer()

  return (
    <Link
      href={
        loggedIn ? '/dashboard/profile' : `/login?redirect=${router.asPath}`
      }
    >
      <UserInfoEl onClick={() => toggle(false)}>
        {loggedIn ? (
          <div className='info'>
            <H6>{name}</H6>
            <S1>{email}</S1>
          </div>
        ) : (
          <Text className='login-register'>Login / Register</Text>
        )}

        <div className='userIcon'>
          {!picture ? (
            <PersonIcon />
          ) : (
            <Image
              height={dimensions.vw(9)}
              quality={25}
              src={picture}
              width={dimensions.vw(9)}
            />
          )}
        </div>
      </UserInfoEl>
    </Link>
  )
}

export default UserInfo
