/* eslint-disable import/prefer-default-export */
import { Button } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { accent, darkAccent, background0 } from '../../../styles/colors'

export const ButtonEl = styled(Button)`
  && {
    text-transform: none;
    font-size: 1em;

    &.Mui-disabled {
      opacity: 0.75 !important;
    }

    .MuiSvgIcon-root {
      fill: white;
    }

    ${({ theme }) =>
      theme === 'primary'
        ? css`
            background: ${darkAccent}
              linear-gradient(196.67deg, ${darkAccent} 0%, ${accent} 100%);
            color: white;
            padding-left: 1.5em;
            padding-right: 1.5em;
            transition: transform 150ms ease;
            font-weight: bold;

            &:active {
              transform: scale(0.9);
            }
          `
        : theme === 'secondary'
        ? css`
            color: white;
            border: 1px solid white;
            padding-left: 1.5em;
            padding-right: 1.5em;
          `
        : theme === 'disabled'
        ? css`
            background: ${background0}
              linear-gradient(196.67deg, ${darkAccent} 0%, ${accent} 100%);
            color: white;
            padding-left: 1.5em;
            padding-right: 1.5em;
            transition: transform 150ms ease;
            font-weight: bold;

            &:active {
              transform: scale(0.9);
            }
            filter: saturate(0);
            -webkit-filter: saturate(0);
            opacity: 0.5;
          `
        : css``};

    &:active {
      transform: scale(0.95);
    }
  }
`
